import * as _0 from "./alliance/alliance";
import * as _1 from "./alliance/delegations";
import * as _2 from "./alliance/events";
import * as _3 from "./alliance/genesis";
import * as _4 from "./alliance/gov";
import * as _5 from "./alliance/params";
import * as _6 from "./alliance/query";
import * as _7 from "./alliance/redelegations";
import * as _8 from "./alliance/tx";
import * as _9 from "./alliance/unbonding";
import * as _423 from "./alliance/tx.amino";
import * as _424 from "./alliance/tx.registry";
import * as _425 from "./alliance/query.rpc.Query";
import * as _426 from "./alliance/tx.rpc.msg";
import * as _707 from "./rpc.query";
import * as _708 from "./rpc.tx";
export namespace alliance {
  export const alliance = {
    ..._0,
    ..._1,
    ..._2,
    ..._3,
    ..._4,
    ..._5,
    ..._6,
    ..._7,
    ..._8,
    ..._9,
    ..._423,
    ..._424,
    ..._425,
    ..._426
  };
  export const ClientFactory = {
    ..._707,
    ..._708
  };
}